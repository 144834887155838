@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

video {
  margin-top: 20px;
  width: 100%;
  max-width: 600px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.br_celular{
  display: none;
}
.tx_obrigatorio{
  color: red;
}

.esconde_primeiro{
  display: none !important;
}

.table_historico{
  border:1px solid #000;
  width: 100%;
  height:400px; 
  overflow-y:scroll;
  font-size: 12px;
}

.campo_texto_historico{
  border:1px solid #000;
  width: 100%;
  height:60px; 
}

.campo_texto_historico_tr{
  border:1px solid #000;
}
.btn_add_historico{
  border:1px solid #000;
  background-color: #36ec26;
  float: right;
  padding-left: 5px;
  padding-right: 5px;
}


form input, select{
  height: 40px;
}      

form input[type=radio]{
  width: 1em;
  height: 1em;
}  

form input[type=checkbox]{
  width: 1em;
  height: 1em;
}  

.radio_check{
  height: 20px;
}

.obrigatorio{
  border: 1px solid red;
}

.obrigatorio2{
  color:red;
}

.Llink_d{
  margin-top: 20px;
  margin-bottom: 10px;
  color: blue;
  display: none;
}

.Llink_a{
  margin-top: 20px;
  margin-bottom: 10px;
  color: blue;
  display: block;
}

.versao{
  margin-top: 10px;
  font-size: 8px;
  color:darkgrey;
  text-align: center;
}



.caixa_atividades{
  border: 1px solid #ccc;
  height: 300px;
  overflow-y: scroll;
  width: 700px !important;
  
}

.div_grupo_produtos_pai{
  width: 100%;
  height: 300px;
  overflow-x: auto;
}

.div_grupo_produtos{
  width: 70%;
  height: 550px;
}

.espaco_top20{
  margin-top: 20px;
}

.tm95{
  width: 95%;
}

.tm100p{
  width: 100%;

}

.tm20{
  width: 20px;
}

.h10{
  height: 10px;
}

.h20{
  height: 20px;
}
.h30{
  height: 30px;
}
.h40{
  height: 40px;
}
.h50{
  height: 50px;
}
.espaco0topo{
  margin-top: 0%;
  padding-top: 0%;
}
.espaco_topo_5{
  margin-top: 5px;
}
.espaco_topo_10{
  margin-top: 10px;
}

.bgteste{
  height: 80px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
  width: 100%;
 
}

.btn25{
height: 25px;
font-size: 12px;
line-height: 50%;
}

.btn30{
  height: 30px;
  font-size: 15px;
  line-height: 50%;
  }
  

.h40 select{
  height: 40px;
}

.item_carrinho{
  width: 98%;
  height: 60px;
  border: 1px solid #ccc;
  margin-top: 5px;
  margin-left: 1%;
  margin-bottom: 5px;
  padding-left: 2%;
  padding-bottom: 0;
}

.texto_atividade{
  font-size: 12px;
  font-weight: bold;
}

.texto_palestrante{
  font-size: 10px;
}

.excluir{
  cursor: pointer;
}

.w30{
  width: 30px;
}
.w150{
  width: 150px;
}
.w200{
  width: 190px;
}

.txred{
 
  color:red;
  font-size: 17.3px;
  font-weight: bold;
}

.txblue{
  
  color:blue;
  font-size: 17.2px;
  font-weight: bold;
}

.w200{
  width: 100px;
}

.cs{
  cursor: pointer;
}
.espaco_b10{
  margin-bottom: 20px;
}

.btnBlack{
  background-color: #000;
  color:#fff;
  flex: 1;
  order: 3;
  width: auto;
}

.btnDanger{
  flex: 1;
  order: 1;
  margin-left: 0;
  width: auto;
}
.btnRed{
  background-color: #ff0000;
  color:#fff;
  flex: 1;
  order: 2;
  margin-left: auto;
  width: auto;
}

.wteste{
  width: 200px;
  background-color: #36ec26;
}

.espaco_atv{
  width: 100%;
  height: 30px;
}

.espaco_atv2{
  width: 100%;
  height: 1px;
}

.f10{
  font-size: 10px;
}

.f14{
  font-size: 14px;
}

.topo{
  margin-top: -30px;
}

.text-grupo{
  color:blue;
  margin-top: 15px;
  margin-bottom: 10px;
  font-weight: bold;
}

.teste{
  background-color: #36ec26;
}

.item_atividade_celular{
  font-size: 14px;
}

.item_atividade_celular_palestrante{
  font-size: 12px;
}

.item_produto{
  width: 100% !important;
}
.item_produto2{
  width: 30% !important;
  text-align: center;
  padding-left: 7%;
}
.item_produto3{
  width: 30% !important;
  text-align: center;
  padding-left: 7%;
}

.btnRed2{
  background-color: #ff0000;
}

.some_excluir{
  display: none;
}

.tbl{
border: 1px solid #ccc;
width: 100%;
max-height: 300px;
overflow-y: scroll;
border-collapse: collapse;
}

.tbltd {
  border: 1px solid #ddd; /* Adiciona bordas leves às células */
  padding: 8px; /* Adiciona espaço interno às células */
  text-align: left; /* Alinha o texto à esquerda */
}

.tbl_c{
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
}

.f10_alert{
  font-size: 10px;
  color:#ff0000;
}

.fundoCadastro{
  background-color: #17a2b8;
}

.border{
  border:1px solid #ccc;
}

.tmTotais{
  width: 700px !important;
}

.pdt10{
  margin-top: 10px;
}

.carrinho{
  margin-top: -35px;
  cursor: pointer;
}

.borderb{
  border-bottom:1px solid #ccc;
} 

.borderr{
  border-right:1px solid #ccc;
}

.cursor{
  cursor: pointer;
}

@media only screen
and (min-width: 801px)
      {
        .grid{
          min-width: 700px;
        }

      }


@media only screen
and (max-width: 800px)
      {
        .remove_celular{
          display: none;
        }
        .br_celular{
          display: block;
          width: 100%;
          height: 30px;
        }
        .cad_celular{
          width: 150px !important;
          text-align: center;
          display: flex;
          align-items: center;
        }
        .left_celular{
          margin-left: -15%;
        }

        .w200{
          width: 30px !important;
          font-size: 12px !important;
        }
        .w_mobile_total{
         
          background-color: #000 !important;
        }

        .caixa_atividades{
          border: 1px solid #ccc;
          height: 240px;
          overflow-y: scroll;
          width: 100% !important;
        }

        .tmTotais{
          width: 100% !important;
        }

        .w_atv_celular{
          width: 100%;
        }

        .espaco_atv{
          display: none;
        }

        .tx10{
          font-size: 10px;
        }

        .h30_celular{
          border:1px solid #fb923c;
          height: 40px;
          margin-bottom: 0;
          font-size: 12px;
        }

        .f12_celular{
          font-size: 12px;
        }

        .btn25_celular{
          height: 35px;
          font-size: 12px;
          line-height: 90%;
          }

          .cupom_celular{
            width: 100%;
            height: 30px;
            margin-bottom: 10px;
          }
          
          .item_atividade_celular{
            font-size: 10px;
          }
          .item_atividade_celular_palestrante{
            font-size: 8px;
          }
          .espaco_topo_20_celular{
            width: 100%;
            height: 20px;
          }

          .topo{
            margin-top: -10px;
          }
          .item_produto3{
            width: 50% !important;
          }
          .preco_celular{
            font-size: 10px;
            text-align: center;
            padding-right: 5%;
          }
          .descricao_item_celular{
            width: 60% !important;
          }
      }

      .ww{
        background-color: #ff0000;
        width: 100%;

      }

     


      